#root {
    white-space: pre-line;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', 'Roboto', Arial, sans-serif;
}

p {
    line-height: 1.5;
}

small {
    font-size: 80%;
    font-weight: 400;
}

html {
    font-size: 62.5%;
}

body {
    font-size: 1.6rem;
    height: 100vh;
    overflow-y: hidden;
}

strong {
    font-weight: bolder;
}

.grecaptcha-badge {
    display: none;
}

.tooltip-inner-container-light-theme {
    display: inline-block !important;
    width: auto !important;
    max-width: 20rem;
    text-align: center;
    padding: 1rem 1.5rem;
    background-color: #000000;
    color: #ffffff;
    font-weight: 300;
    font-family: 'Fira Sans', sans-serif;
    font-size: 1.4rem;
    border-color: 'rgb(204, 204, 204)';
    border-radius: 0.7rem;
    line-height: 2.1rem;
    box-shadow: rgba(0, 0, 0, 0.1) 0 0.2rem 1rem 0;
}

.tooltip-inner-container-dark-theme {
    display: inline-block !important;
    width: auto !important;
    max-width: 20rem;
    text-align: center;
    padding: 1rem 1.5rem;
    background-color: #ffffff;
    color: #000000;
    font-weight: 300;
    font-family: 'Fira Sans', sans-serif;
    font-size: 1.4rem;
    border-color: 'rgb(204, 204, 204)';
    border-radius: 0.7rem;
    line-height: 2.1rem;
    box-shadow: rgba(0, 0, 0, 0.1) 0 0.2rem 1rem 0;
}

.Toastify__toast-container {
    z-index: 99999;
}